<!--
 * @Description: 设置打印信息
 * @Author: ChenXueLin
 * @Date: 2022-01-04 16:34:29
 * @LastEditTime: 2022-03-30 16:27:46
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <el-button type="primary" @click="toSend">
      下发指令
    </el-button>
    <el-form
      ref="writeForm"
      label-width="130px"
      :model="writeForm"
      :rules="writeFormRules"
    >
      <el-form-item prop="serverDetails" label="服务内容">
        <el-input
          v-model="writeForm.serverDetails"
          placeholder="服务内容"
          type="textarea"
          :rows="5"
        >
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import base from "@/mixins/base";

export default {
  name: "",
  components: {},
  data() {
    return {
      writeForm: {
        serverDetails: ""
      },
      writeFormRules: {
        serverDetails: [
          {
            required: true,
            message: "请输入服务内容",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  mixins: [base],
  computed: {},
  watch: {},
  methods: {
    toSend() {
      window.open("http://devman.e6yun.com", "_blank");
    },
    //重置表单
    resetForm() {
      this.writeForm = {
        serverDetails: ""
      };
      this.$refs.writeForm.resetFields();
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
.el-button {
  margin: 20px;
}
/deep/.el-form {
  .el-form-item {
    .el-textarea {
      width: 400px;
    }
  }
}
</style>
